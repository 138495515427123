/* eslint-disable import/no-named-as-default */
'use client'

import { PostHogProvider as PHProvider } from 'posthog-js/react'
import PostHogPageView from './pageview'
import posthog from 'posthog-js'
import { useEffect } from 'react'

export function PostHogProvider({ children }: { children: React.ReactNode }) {
  useEffect(() => {
    posthog.init(process.env.NEXT_PUBLIC_POSTHOG_KEY as string, {
      api_host: process.env.NEXT_PUBLIC_POSTHOG_HOST,
      capture_pageview: false, // Disable automatic pageview capture, as we capture manually
      capture_pageleave: true, // Enable pageleave capture
      loaded: (posthog) => {
        if (process.env.NODE_ENV === 'development') posthog.debug() // debug mode in development
      },
    })
  }, [])

  return (
    <PHProvider client={posthog}>
      <PostHogPageView />
      {children}
    </PHProvider>
  )
}
