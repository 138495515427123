/* eslint-disable @typescript-eslint/no-unused-vars */

export const menusWithSubChildren = ['/industries'] as const

export interface SubMenuItem {
  name: string
  slug: string
}

export interface SubMenuItemWithChildren {
  name: string
  slug: string
  items: SubMenuItem[]
}

export interface MenuItemWithCopy {
  name: string
  slug: '/about' | '/approach' | '/insights' | '/join' // exactly typed to help with NextJS Link route checking
  copy: string
}

export interface MenuItemWithSubChildren {
  name: string
  slug: (typeof menusWithSubChildren)[number] // exactly typed to help with NextJS Link route checking
  items: SubMenuItemWithChildren[]
}

export interface MenuItemWithChildren {
  name: string
  slug: '/services' // exactly typed to help with NextJS Link route checking
  items: SubMenuItem[]
}

type MenuItem =
  | MenuItemWithCopy
  | MenuItemWithChildren
  | MenuItemWithSubChildren

const servicesItems: SubMenuItem[] = [
  { name: 'Strategy & Advisory', slug: 'strategy-and-advisory' },
  { name: 'Data Analytics & Insights', slug: 'data-analytics-and-insights' },
  { name: 'Artificial Intelligence', slug: 'artificial-intelligence' },
  { name: 'Product', slug: 'product' },
  {
    name: 'Data & Application Modernisation',
    slug: 'data-and-app-modernisation',
  },
  { name: 'Intelligent Platforms', slug: 'intelligent-platforms' },
]
const services: MenuItemWithChildren = {
  name: 'Services',
  slug: '/services',
  items: servicesItems,
}

const industriesItems: SubMenuItemWithChildren[] = [
  {
    name: 'Financial Services',
    slug: 'financial-services',
    items: [
      { name: 'Banking', slug: 'banking' },
      { name: 'Insurance', slug: 'insurance' },
      { name: 'Capital Markets', slug: 'capital-markets' },
    ],
  },
  {
    name: 'Energy & Utilities',
    slug: 'energy-utilities',
    items: [
      { name: 'Trading', slug: 'trading' },
      { name: 'Network Operations', slug: 'network-operations' },
      { name: 'Retail', slug: 'retail' },
    ],
  },
  {
    name: 'Retail',
    slug: 'retail',
    items: [
      { name: 'Product Retail', slug: 'product-retail' },
      { name: 'Retail Services', slug: 'retail-services' },
      { name: 'Marketing & Merchandising', slug: 'marketing-merchandise' },
    ],
  },
]
const industries: MenuItemWithSubChildren = {
  name: 'Industries',
  slug: '/industries',
  items: industriesItems,
}

const about: MenuItemWithCopy = {
  name: 'About',
  slug: '/about',
  copy: "We're one of the fastest growing consultancies in APAC",
}
const approach: MenuItemWithCopy = {
  name: 'Approach',
  slug: '/approach',
  copy: 'Find out what makes V2 AI different',
}
const insights: MenuItemWithCopy = {
  name: 'Insights',
  slug: '/insights',
  copy: 'Explore expert Data and AI trends',
}
const join: MenuItemWithCopy = {
  name: 'Join',
  slug: '/join',
  copy: 'Ready to make your mark?',
}

export const headerMenu: MenuItem[] = [
  about,
  services,
  industries,
  insights,
  join,
]

type MenuItemsArray = Array<
  MenuItemWithCopy | MenuItemWithChildren | MenuItemWithSubChildren
>

export const footerLeft: MenuItemsArray = [about, insights, join, services]
export const footerRight: MenuItemsArray = [industries]

export const menuItemIsWithChildren = (
  item: MenuItem,
): item is MenuItemWithChildren => 'items' in item
