import { createLucideIcon } from 'lucide-react'
export const TriangleDown = createLucideIcon('TriangleDown', [
  [
    'path',
    {
      d: 'm1.5 1.5 10.5 21 10.5-21z',
      key: 'bfja5c',
    },
  ],
])
